import React from "react";
import {graphql} from "gatsby";
import {get, isEmpty} from "lodash";
import useViewportSizes from "use-viewport-sizes";
import Link from "components/link";
import Img from "components/image";
import DefaultLayout from "components/layout/default";
import OutlineText from "components/outline-text";
import {EXTERNAL_LINK_PROPS} from "constants/index";
import blogStyle from "styles/pages/blog.module.css";
import layoutStyle from "styles/layout.module.css";
import twitterIcon from "images/blog-twitter-icon.svg";
import facebookIcon from "images/blog-facebook-icon.svg";
import linkedinIcon from "images/blog-linkedin-icon.svg";
import BlogSubsribe from "components/blog-subscribe/index";

function BlogPostTemplate(props) {
  let pageContent = props.data.pageContent.childMarkdownRemark;
  let heroTextRef = React.useRef();
  let viewportWidth = React.useRef();
  let [heroTextHeight, setHeroTextHeight] = React.useState();
  let viewportSizes = useViewportSizes();
  React.useLayoutEffect(() => {
    updateHeroTextHeight();
    setTimeout(updateHeroTextHeight, 200);
    setTimeout(updateHeroTextHeight, 600);
    window.addEventListener("resize", updateHeroTextHeight);
    return () => document.removeEventListener("resize", updateHeroTextHeight);
  }, []);

  React.useLayoutEffect(() => {
    viewportWidth.current = viewportSizes[0];
  }, [viewportSizes]);

  React.useEffect(() => {
    updateHeroTextHeight();
  }, [heroTextRef, viewportWidth]);

  function updateHeroTextHeight() {
    if (heroTextRef.current) {
      if (viewportWidth.current != null && viewportWidth.current < 1024) {
        setHeroTextHeight("auto");
      } else {
        setHeroTextHeight(heroTextRef.current.offsetHeight);
      }
    }
  }

  function isHubspot() {
    let publishTime = new Date(pageContent.frontmatter.date).getTime();
    return publishTime <= 1561347122659;
  }

  function getAuthorImage() {
    let {author} = pageContent.fields;
    let name = get(author, "name", "Author");
    let photoFixedSrc = get(author, "photo.childImageSharp.fixed.src");
    if (photoFixedSrc) {
      return photoFixedSrc;
    } else {
      return `https://ui-avatars.com/api/?name=${name}`;
    }
  }

  function getAuthorPagePath(email) {
    let {author} = pageContent.fields;
    if (author == null) return null;
    return `/blog/author/${author.fields.slug}/`;
  }

  function renderPost(data) {
    let featureImage;
    let featureImageFluid = get(data, "frontmatter.featureImage.childImageSharp.fluid");
    if (featureImageFluid) {
      featureImage = <Img fluid={featureImageFluid} alt={data.frontmatter.title}
        className="w-full float-none bg-gray-300"/>;
    }
    return <Link key={data.id} to={`/blog/${data.fields.slug}/`} className="flex-1">
      {featureImage}
      <div className="text-xs uppercase font-bold mt-4">
        {data.frontmatter.category}
      </div>
      <h2 className="text-xl font-serif font-bold leading-tight">
        {data.frontmatter.title}
      </h2>
      <p className="text-sm mt-2 truncate-4l">
        {isEmpty(data.frontmatter.excerpt) ? data.excerpt : data.frontmatter.excerpt}
      </p>
      <div className="uppercase text-xs mt-2 text-gray-600">
        {get(data, "fields.author.name")} • {data.frontmatter.formattedDate}
      </div>
    </Link>;
  }

  function renderLatestPosts() {
    let posts = props.data.latestPosts.edges.map(item => item.node);
    return [
      renderPost(posts[0]),
      <div key="space-1" className="h-8 sm:h-0 sm:w-6 lg:w-12"/>,
      renderPost(posts[1]),
      <div key="space-2" className="h-8 sm:h-0 sm:w-6 lg:w-12"/>,
      renderPost(posts[2])
    ];
  }

  function renderArticle() {
    let articleStyle = blogStyle.article;
    if (isHubspot()) articleStyle = blogStyle.articleHubspot;
    return <article dangerouslySetInnerHTML={{__html: pageContent.html}}
      className={articleStyle + " container mx-auto px-4 md:px-8 xl:px-56 py-6 md:py-16"}/>;
  }

  function renderFeatureImage() {
    let featureImageFluid = get(pageContent, "frontmatter.featureImage.childImageSharp.fluid");
    if (featureImageFluid) {
      return <Img fluid={featureImageFluid} alt={pageContent.frontmatter.title}
        style={{height: heroTextHeight}}
        className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:pl-8 object-cover object-center relative lg:absolute top-0 right-0"/>;
    }
  }

  return <DefaultLayout location={props.location}
    headerFill="always"
    title={pageContent.frontmatter.title}
    description={pageContent.frontmatter.description}
    metaImage={get(pageContent, "frontmatter.featureImage.childImageSharp.fixed.src")}>
    <div className={layoutStyle.headerPlaceholder}/>
    <div className="z-10 relative bg-white text-black py-4 sm:py-20 mb-8 sm:mb-20">
      <header className="container mx-auto px-4 md:px-8 items-center">
        <div className="relative flex flex-col-reverse lg:flex-row">
          <div ref={heroTextRef} className="w-full lg:w-1/2 sm:pr-8">
            <div className="uppercase font-bold">{get(pageContent, "frontmatter.category")}</div>
            <h1 className="font-serif text-4xl sm:text-6xl font-semibold"
              style={{lineHeight: "1.1"}}>
              {pageContent.frontmatter.title}
            </h1>
            <p className="mt-4 sm:mt-8 sm:text-lg leading-loose">
              {isEmpty(pageContent.frontmatter.excerpt) ? pageContent.excerpt : pageContent.frontmatter.excerpt}
            </p>
          </div>
          {renderFeatureImage()}
        </div>
      </header>
      <div className="container mx-auto px-4 md:px-8 mt-8 sm:mt-16">
        <div className="flex flex-col-reverse sm:flex-row border-b border-gray-200 pb-4">
          <div className="sm:w-1/2 flex items-center items-left">
            <Link to={getAuthorPagePath()}>
              <img className="rounded-full h-10 w-10 justify-center object-cover object-center"
                src={getAuthorImage()}
                alt={get(pageContent, "fields.author.name")}/>
            </Link>
            <span className="font-sans pl-4">
              <span>By: </span>
              <Link className="underline" to={getAuthorPagePath()}>
                {get(pageContent, "fields.author.name")}
              </Link>
              , {pageContent.frontmatter.formattedDate}
            </span>
          </div>
            <div className="sm:w-1/2 flex items-center sm:justify-end mb-6 sm:mb-0 -ml-4">
              <a href={process.env.GATSBY_TWITTER_URL} {...EXTERNAL_LINK_PROPS}>
                <img className="w-6 h-6 ml-4 inline-block" src={twitterIcon} alt="Twitter"/>
              </a>
              <a href={process.env.GATSBY_FACEBOOK_URL} {...EXTERNAL_LINK_PROPS}>
                <img className="w-6 h-6 ml-4 inline-block" src={facebookIcon} alt="Facebook"/>
              </a>
              <a href={process.env.GATSBY_LINKEDIN_URL} {...EXTERNAL_LINK_PROPS}>
                <img className="w-6 h-6 ml-4 inline-block" src={linkedinIcon} alt="LinkedIn"/>
              </a>
          </div>
        </div>
      </div>
      {renderArticle()}
      <footer className="container mx-auto px-4 md:px-8 items-center">
        <div className="flex flex-col-reverse sm:flex-row py-8 sm:py-8">
          <div className="flex flex-1">
            <Link to={getAuthorPagePath()}>
              <img className="rounded-full h-10 w-10 flex-none object-cover object-center"
                src={getAuthorImage()}
                alt={get(pageContent, "fields.author.name")}/>
            </Link>
            <div className="pl-4 flex-1">
              <div className="font-sans">
                <span>By: </span>
                <Link className="underline" to={getAuthorPagePath()}>
                  {get(pageContent, "fields.author.name")}
                </Link>
                , {pageContent.frontmatter.formattedDate}
              </div>
              <p className="text-sm mt-2 text-gray-600">
                {get(pageContent, "fields.author.biography")}
              </p>
            </div>
          </div>
          <div className="flex-none mb-6 sm:mb-0 -ml-4 sm:ml-4">
            <a href={process.env.GATSBY_TWITTER_URL} {...EXTERNAL_LINK_PROPS}>
              <img className="w-6 h-6 ml-4 inline-block" src={twitterIcon} alt="Twitter"/>
            </a>
            <a href={process.env.GATSBY_FACEBOOK_URL} {...EXTERNAL_LINK_PROPS}>
              <img className="w-6 h-6 ml-4 inline-block" src={facebookIcon} alt="Facebook"/>
            </a>
            <a href={process.env.GATSBY_LINKEDIN_URL} {...EXTERNAL_LINK_PROPS}>
              <img className="w-6 h-6 ml-4 inline-block" src={linkedinIcon} alt="LinkedIn"/>
            </a>
          </div>
        </div>
      </footer>
      <section className="bg-black text-white -mb-10 mt-5">
        <BlogSubsribe imgFluid={props.data.newsletterVisualImage.childImageSharp.fluid}/>
      </section>
      <div className="container mx-auto mt-12 mb-4 sm:mt-24 px-4 md:px-8">
        <OutlineText tagName="h2" color="#1515FF"
          className="font-sans text-3xl sm:text-5xl font-black leading-none">
          Explore:
        </OutlineText>
        <h2 className="font-sans text-3xl sm:text-5xl font-black leading-none mb-6 sm:mb-10 mt-2"
          style={{color: "#1515FF"}}>
          Other Articles
        </h2>
        <div className="flex flex-col sm:flex-row">
          {renderLatestPosts()}
        </div>
      </div>
    </div>
  </DefaultLayout>;
}

export default React.memo(BlogPostTemplate);

export const QUERY = graphql`
  query BlogPostTemplateQuery($fileId: String!) {
    pageContent: file(id: {eq: $fileId}) {
      childMarkdownRemark {
        id
        html
        fields {
          author {
            name
            photo {
              childImageSharp {
                fixed(width: 120, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            biography
            fields {
              slug
            }
          }
        }
        excerpt(pruneLength: 400)
        frontmatter {
          title
          description
          author
          excerpt
          category
          featureImage {
            childImageSharp {
              fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
              fixed(width: 1200, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          date
          formattedDate: date(formatString: "MMM DD, YYYY")
        }
      }
    }
    latestPosts: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/\/content\/blog-posts\//"}},
      sort: {fields: [frontmatter___date], order: DESC},
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
            author {
              name
            }
          }
          excerpt(pruneLength: 400)
          frontmatter {
            title
            author
            excerpt
            category
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            formattedDate: date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
    newsletterVisualImage: file(relativePath: { eq: "newsletter-visual.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
